.monthItem {
  color: #ffffff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.monthItem:hover {
  background-color: #7269ef;
}

.monthItem:active {
  background-color: #7269ef;
}

.contentMonth {
  background-color: #36404a;
  width: 250px;
  padding: 15px;
}

input:focus {
  border: 1px solid #7269ef;
  outline: none;
  box-shadow: 0 0 5px rgba(114, 105, 239, 0.7);
}

div.rdrCalendarWrapper > div.rdrMonths > div.rdrMonth {
  width: 100%;
}

div.rdrCalendarWrapper > div.rdrMonths > div.rdrMonth > div.rdrMonthName {
  color: #7269ef;
}

span.rdrDayNumber span {
  color: #ffffff;
}

button.rdrDayPassive .rdrDayNumber span {
  color: #818283;
}

div.rdrCalendarWrapper {
  background: #262e35;
}

.rdrMonthAndYearPickers span select {
  color: #ffffff
}

.rdrMonthAndYearWrapper button {
  background: #7269ef;

}

div.rdrCalendarWrapper > div.rdrDateDisplayWrapper {
  background: #262e35;
}

div.rdrCalendarWrapper > div.rdrDateDisplayWrapper > div.rdrDateDisplay > span.rdrDateDisplayItem  {
  background-color: #7269ef;
}

div.rdrCalendarWrapper > div.rdrDateDisplayWrapper > div.rdrDateDisplay > span.rdrDateDisplayItem > input {
  color: #ffffff;
}

span.rdrMonthAndYearPickers > span > select option {
  margin: 40px;
  background: #262e35;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.sidebarSchedules {
  background-color: #36404a;
  width: 35%;
}