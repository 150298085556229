@font-face {
    font-family: "DejaVu Sans";
    src: url(DejaVuSans.ttf) format("truetype");
}

.dragging {
    cursor: grabbing !important;
}

.calc-height {
    height: calc(100% - 70px);
}
 
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #505050;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #7269ef;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7269ef;
}